.accordion-main {
    font-size: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 50px;
    z-index: 99;
    height: auto;
    gap: 20px;
    color: #000000;
}
.accordion-main p {
    margin: 0 !important;
}
.accordion-header {
    display: flex;
}
.accordion-header p{
    text-align: left;
    color: #535878;
    padding: 10px;
    background-color: white;
    border: 1px solid white;
    font-size: 18px;
}


.accordion-introduction {
    display: flex;
    flex-direction: column;
    text-align: left;
}
.MuiButtonBase-root:hover {
    background-color: rgba(0,0,0,0.05);
}

.accordion-introduction-title {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.accordion-introduction-title h2 {
   font-size: 24px;
}

.accordion-introduction-title-icon {
    width: 50px;
    height: 50px;
}

.accordion {
    text-align: left;
}
@media (max-width: 768px) {
    .accordion-main {
        padding: 20px !important;
    }

}