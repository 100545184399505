.about-us-main {
    display: flex;
    justify-content: center;
    box-shadow: inset 1px 1px 50px #232323;
    background-color: #3c3c3c;
    color: #cac9c9;
    padding: 150px 100px;
}
.about-us-container {
    width: 100%;
    max-width: 1240px;
    gap: 50px;
    display: flex;
    flex-direction: column;
}
.about-us-title h2{
    padding: 10px 0%;
    font-size: 36px;
    color: #c89165;
}
.about-us-text {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 24px;
}

.about-us-rights {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
}
.about-us-rights-card{
    border-radius: 35px;
    box-shadow: 1px 1px 10px #101010;
    color: #151414;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    padding: 20px;
    width: 32.5%;
    height: auto;
    min-height: 250px;
    background: linear-gradient(#ce9c6a, #e8ab75,#ce9c6a)
}

@media (max-width: 1024px) {
    .about-us-main {
        padding: 100px 20px;
    }
    .about-us-rights {
        justify-content: center;
    }
    .about-us-rights-card {
        width: 48%  ;
        min-height: 30px;
    }
}
@media (max-width: 768px) {
    .about-us-main {
        padding:  100px 20px !important;
    }
    .about-us-rights {
        width: 100%;
        flex-wrap: nowrap !important;
        flex-direction: column !important;
        justify-content: center;
    }
    .about-us-rights-card {
        width: 100% ;
    }
    .about-us-text {
        font-size: 18px!important;
    }
}