.contact-form-main {
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    padding: 50px;
    gap: 30px;
    font-size: 16px;
    color: #0b0a0a;
}
.contact-form-header{
    display: flex;
}
.contact-form-header p {
    text-align: left;
    color: #535878;
    padding: 10px;
    background-color: white;
    border: 1px solid white;
    font-size: 18px;
}

.contact-form-introduction {
    display: flex;
    gap: 30px;
    flex-direction: column;
    text-align: left;
}

.contact-form-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.contact-form-title h2{
    font-size: 24px;
}

.form-email-icon {
    width: 30px;
    height: 30px;
}

.contact-form button {
    border-radius: 20px;
    margin-top: 50px;
    background-color: black;
    color: white;
    width: 100%;
    height: 50px;
}
.accordion-full {
    margin-top: 25px;
}

@media (max-width: 768px) {
    .contact-form-main {
        padding: 20px !important;
    }

}