.model-services-main{
    display: flex;
    justify-content: center;
    padding: 150px 100px;
    width: 100%;
    background-color: #202020;
}
.model-services-container {
    border-radius: 25px;
    box-shadow: 1px 1px 50px #1b1a1a;
    display: flex;
    background-color: #3c3c3c;
    width: 100%;
    max-width: 1240px;
    height: auto;
    gap: 50px;
}
.model-services-container-left {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px;
}
.model-services-container-left h1 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.model-services-container-left h1 svg {
    margin-top: 5px;
}
.model-services-title {
    display: flex;
    width: 100%;
    height: auto;
    color:  #ca8955;
    font-size: 36px;
}
.model-services-contact, .model-services-strategy , .model-services-execution, .model-services-help  {
    display: flex;
    gap: 20px;
}
.model-services-number {
    display: flex;
    position: relative;
    background-color: #e8ab75;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-weight: bold;
    font-size: 20px;
    width: 100px;
    min-width: 100px;
    height: 100%;
    max-height: 150px;
    min-height: 150px;
}

.model-services-number::before{
    content: "";
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
}
.model-services-number::after {
    content: "";
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    right: 0;
    border-style: solid;
}

.model-services-number::before {
    top: 0;
    position: absolute;
    border-width: 0 50px 25px 50px;
    border-color: transparent transparent #3c3c3c transparent;
    transform: rotate(180deg);
}
.model-services-number::after{
    bottom: 0;
    position: absolute;
    transform: rotate(180deg);
    border-width: 0 50px 25px 50px;
    border-color: #3c3c3c #3c3c3c transparent #3c3c3c ;
    ;

}
.model-services-content {
    color: #ccc8c8;
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 18px;
    height: auto;
}
.model-services-container-right {
    display: flex;
    height: 100%;
    width: 70%;
    object-fit: cover;
    gap: 30px;
}
.model-services-container-right img {
    display: flex;
    width: 100%;
    border-radius: 25px;
    object-fit: cover;
}

@media (max-width: 1024px) {
    .model-services-container-right {
        display: none;
    }
}

@media (max-width: 768px) {

    .model-services-main{
        padding: 100px 20px !important;
    }
    .model-services-container-left {
        padding: 10px !important;
    }
    .model-services-title {
        width: 100%;
        font-size: 32px;
        text-align: left;
    }
    .model-services-content {
        gap: 5px !important;
    }
    .model-services-content header h2 {
        font-size: 20px;
    }
    .model-services-content p {
        font-size: 16px;
    }
    .model-services-number {
        width: 70px !important;
        min-width: 70px !important;
        height: 100% !important;
        max-height: 200px !important;
    }

    .model-services-number::before {
        top: 0;
        position: absolute;
        border-width: 0 35px 15px 35px;
        border-color: transparent transparent #3c3c3c transparent;
        transform: rotate(180deg);
    }
    .model-services-number::after{
        bottom: 0;
        position: absolute;
        transform: rotate(180deg);
        border-width: 0 35px 15px 35px;
        border-color: #3c3c3c #3c3c3c transparent #3c3c3c ;
    ;
    }
}
