.header-menu-bar-main{
    display: flex;
    color: white;
    width: 100%;
    height: 100%;
    font-family: "Gilda Display", serif;
}
.header-menu-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.header-menu-bar-container-logo{
    padding: 20px;
}
.header-menu-bar-container-logo img{
    width: 240px;
    height: 45px;
}
.header-menu-bar-container-list {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.header-menu-bar-container-list a{
    text-align: center;
    cursor: pointer;
    padding: 20px;
    width: 100%;
    transition: 0.3s;
    border-bottom: 1px solid gray;
}
.header-menu-bar-container-list a:hover {
    font-weight: bold;
    padding: 25px;
}