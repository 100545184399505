.footer-main {
    display: flex;
    width: 100%;
    height: auto;
    box-shadow: inset 1px 1px 100px rgba(23, 23, 23, 0.8);
    background: linear-gradient(#272727, #2b1f18, #322116, #0c0c0c);
    padding: 50px;
}

.footer-main-container {
    display: flex;
    color: #d6d1d1;
    width: 100%;
    gap: 50px;
    align-items: center;
    flex-direction: column;
}

.footer-main-container-logo {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 20px;
    width: 100%;
}

.footer-main-container-logo img {
    width: 500px;
    height: 93px;
}

.footer-main-container-content {
    border-bottom: 1px solid #ca8955;
    border-top: 1px solid #ca8955;
    padding: 50px;
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    height: auto;
}

.footer-main-container-content-location,
.footer-main-container-content-reservation,
.footer-main-container-content-contact {
    display: flex;
    width: 100%;
    padding: 20px;
    height: 250px;
    min-height: 250px;
    font-style: italic;
    justify-content: space-between;
    flex-direction: column;
    gap: 50px;
}

.footer-main-container-content-reservation {
    border-right: 1px solid #ca8955;
    border-left: 1px solid #ca8955;
}

.footer-main-container-content-contact-icons {
    width: 100%;
    justify-content: center;
    height: 100%;
    display: flex;
    gap: 20px;
}

.footer-main-container-content-contact-icons svg {
    width: 35px;
    height: 35px;
}

.footer-main-container-content-location address,
.footer-main-container-content-reservation p {
    display: flex;
    align-items: center;
    text-align: center;
    color: #d6d1d1;
    height: 100%;
    gap: 20px;
    flex-direction: column;
}
.footer-main-container-content-reservation a{
    text-align: center;
}

.footer-main-container-content-location h3,
.footer-main-container-content-reservation h3,
.footer-main-container-content-contact h3 {
    letter-spacing: 1px;
    font-weight: bold;
    color: white;
    font-size: 18px;
    text-shadow: 1px 1px 1px gray;
    text-align: center;
}

.footer-main-container-liscense {
    display: flex;
    width: 100%;
    padding: 50px;
    justify-content: center;
}

.footer-main-container-liscense p {
    width: 500px;
    font-size: 12px;
    text-align: center;
}
.container-social-icon {
    display: flex;
    height: 100%;
    justify-content: center;
    gap: 20px;
}
.social-icon svg {
    width: 35px;
    height: 35px;
}

@media (max-width: 1025px) {
    .footer-main-container-content {
        padding: 0px !important;
        flex-direction: column !important;
    }

    .footer-main-container-content-reservation {
        border-left: none !important;
        border-right: none !important;
        border-top: 1px solid #8f8f8f;
        border-bottom: 1px solid #8f8f8f;
    }

    .footer-main-container-content-location,
    .footer-main-container-content-reservation,
    .footer-main-container-content-contact {
        gap: 30px !important;
        justify-content: center !important;
    }
    .container-social-icon {
        height: auto !important;
    }

    .footer-main-container-logo img {
        width: 400px;
        height: 74px;
    }

    .footer-main-container-content-location address,
    .footer-main-container-content-reservation p,
    .footer-main-container-content-contact-icons {
        height: auto;
    }
}

@media (max-width: 768px) {
    .footer-main {
        padding: 20px;
    }

    .footer-main-container-content {
        width: 100% !important;
    }

    .footer-main-container-content-reservation {
        border-top: 1px solid #8f8f8f;
        border-bottom: 1px solid #8f8f8f;
    }

    .footer-main-container-logo img {
        width: 320px;
        height: 59px;
    }
}

@media (max-width: 360px){
    .footer-main-container-logo img {
        width: 240px;
        height: 45px;
    }
}
