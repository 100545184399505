/* latin-ext */
@font-face {
  font-family: '__Antonio_5d74ab';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/b37e02353da4ba38-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Antonio_5d74ab';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/21c9b7f6563401f3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Antonio_5d74ab';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b37e02353da4ba38-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Antonio_5d74ab';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/21c9b7f6563401f3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Antonio_Fallback_5d74ab';src: local("Arial");ascent-override: 137.82%;descent-override: 16.61%;line-gap-override: 0.00%;size-adjust: 83.79%
}.__className_5d74ab {font-family: '__Antonio_5d74ab', '__Antonio_Fallback_5d74ab';font-style: normal
}

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(

     to bottom,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}
a {
  color: inherit;
  text-decoration: none;
}


@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.about-us-main {
    display: flex;
    justify-content: center;
    box-shadow: inset 1px 1px 50px #232323;
    background-color: #3c3c3c;
    color: #cac9c9;
    padding: 150px 100px;
}
.about-us-container {
    width: 100%;
    max-width: 1240px;
    gap: 50px;
    display: flex;
    flex-direction: column;
}
.about-us-title h2{
    padding: 10px 0%;
    font-size: 36px;
    color: #c89165;
}
.about-us-text {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 24px;
}

.about-us-rights {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
}
.about-us-rights-card{
    border-radius: 35px;
    box-shadow: 1px 1px 10px #101010;
    color: #151414;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    padding: 20px;
    width: 32.5%;
    height: auto;
    min-height: 250px;
    background: linear-gradient(#ce9c6a, #e8ab75,#ce9c6a)
}

@media (max-width: 1024px) {
    .about-us-main {
        padding: 100px 20px;
    }
    .about-us-rights {
        justify-content: center;
    }
    .about-us-rights-card {
        width: 48%  ;
        min-height: 30px;
    }
}
@media (max-width: 768px) {
    .about-us-main {
        padding:  100px 20px !important;
    }
    .about-us-rights {
        width: 100%;
        flex-wrap: nowrap !important;
        flex-direction: column !important;
        justify-content: center;
    }
    .about-us-rights-card {
        width: 100% ;
    }
    .about-us-text {
        font-size: 18px!important;
    }
}
.accordion-main {
    font-size: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 50px;
    z-index: 99;
    height: auto;
    gap: 20px;
    color: #000000;
}
.accordion-main p {
    margin: 0 !important;
}
.accordion-header {
    display: flex;
}
.accordion-header p{
    text-align: left;
    color: #535878;
    padding: 10px;
    background-color: white;
    border: 1px solid white;
    font-size: 18px;
}


.accordion-introduction {
    display: flex;
    flex-direction: column;
    text-align: left;
}
.MuiButtonBase-root:hover {
    background-color: rgba(0,0,0,0.05);
}

.accordion-introduction-title {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.accordion-introduction-title h2 {
   font-size: 24px;
}

.accordion-introduction-title-icon {
    width: 50px;
    height: 50px;
}

.accordion {
    text-align: left;
}
@media (max-width: 768px) {
    .accordion-main {
        padding: 20px !important;
    }

}
.contact-form-main {
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    padding: 50px;
    gap: 30px;
    font-size: 16px;
    color: #0b0a0a;
}
.contact-form-header{
    display: flex;
}
.contact-form-header p {
    text-align: left;
    color: #535878;
    padding: 10px;
    background-color: white;
    border: 1px solid white;
    font-size: 18px;
}

.contact-form-introduction {
    display: flex;
    gap: 30px;
    flex-direction: column;
    text-align: left;
}

.contact-form-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.contact-form-title h2{
    font-size: 24px;
}

.form-email-icon {
    width: 30px;
    height: 30px;
}

.contact-form button {
    border-radius: 20px;
    margin-top: 50px;
    background-color: black;
    color: white;
    width: 100%;
    height: 50px;
}
.accordion-full {
    margin-top: 25px;
}

@media (max-width: 768px) {
    .contact-form-main {
        padding: 20px !important;
    }

}
.footer-main {
    display: flex;
    width: 100%;
    height: auto;
    box-shadow: inset 1px 1px 100px rgba(23, 23, 23, 0.8);
    background: linear-gradient(#272727, #2b1f18, #322116, #0c0c0c);
    padding: 50px;
}

.footer-main-container {
    display: flex;
    color: #d6d1d1;
    width: 100%;
    gap: 50px;
    align-items: center;
    flex-direction: column;
}

.footer-main-container-logo {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 20px;
    width: 100%;
}

.footer-main-container-logo img {
    width: 500px;
    height: 93px;
}

.footer-main-container-content {
    border-bottom: 1px solid #ca8955;
    border-top: 1px solid #ca8955;
    padding: 50px;
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    height: auto;
}

.footer-main-container-content-location,
.footer-main-container-content-reservation,
.footer-main-container-content-contact {
    display: flex;
    width: 100%;
    padding: 20px;
    height: 250px;
    min-height: 250px;
    font-style: italic;
    justify-content: space-between;
    flex-direction: column;
    gap: 50px;
}

.footer-main-container-content-reservation {
    border-right: 1px solid #ca8955;
    border-left: 1px solid #ca8955;
}

.footer-main-container-content-contact-icons {
    width: 100%;
    justify-content: center;
    height: 100%;
    display: flex;
    gap: 20px;
}

.footer-main-container-content-contact-icons svg {
    width: 35px;
    height: 35px;
}

.footer-main-container-content-location address,
.footer-main-container-content-reservation p {
    display: flex;
    align-items: center;
    text-align: center;
    color: #d6d1d1;
    height: 100%;
    gap: 20px;
    flex-direction: column;
}
.footer-main-container-content-reservation a{
    text-align: center;
}

.footer-main-container-content-location h3,
.footer-main-container-content-reservation h3,
.footer-main-container-content-contact h3 {
    letter-spacing: 1px;
    font-weight: bold;
    color: white;
    font-size: 18px;
    text-shadow: 1px 1px 1px gray;
    text-align: center;
}

.footer-main-container-liscense {
    display: flex;
    width: 100%;
    padding: 50px;
    justify-content: center;
}

.footer-main-container-liscense p {
    width: 500px;
    font-size: 12px;
    text-align: center;
}
.container-social-icon {
    display: flex;
    height: 100%;
    justify-content: center;
    gap: 20px;
}
.social-icon svg {
    width: 35px;
    height: 35px;
}

@media (max-width: 1025px) {
    .footer-main-container-content {
        padding: 0px !important;
        flex-direction: column !important;
    }

    .footer-main-container-content-reservation {
        border-left: none !important;
        border-right: none !important;
        border-top: 1px solid #8f8f8f;
        border-bottom: 1px solid #8f8f8f;
    }

    .footer-main-container-content-location,
    .footer-main-container-content-reservation,
    .footer-main-container-content-contact {
        gap: 30px !important;
        justify-content: center !important;
    }
    .container-social-icon {
        height: auto !important;
    }

    .footer-main-container-logo img {
        width: 400px;
        height: 74px;
    }

    .footer-main-container-content-location address,
    .footer-main-container-content-reservation p,
    .footer-main-container-content-contact-icons {
        height: auto;
    }
}

@media (max-width: 768px) {
    .footer-main {
        padding: 20px;
    }

    .footer-main-container-content {
        width: 100% !important;
    }

    .footer-main-container-content-reservation {
        border-top: 1px solid #8f8f8f;
        border-bottom: 1px solid #8f8f8f;
    }

    .footer-main-container-logo img {
        width: 320px;
        height: 59px;
    }
}

@media (max-width: 360px){
    .footer-main-container-logo img {
        width: 240px;
        height: 45px;
    }
}

.contact-main {
    display: flex;
    justify-content: center;
    padding: 150px 100px;
    height: auto;
    background: linear-gradient(#363636, rgb(32, 32, 32));
    box-shadow: inset 0px 10px 10px #2c2b2b;
}

.contact-main-container {
    width: 100%;
    max-width: 1250px;
    box-shadow: 1px 1px 10px black;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
}

.contact-main-form {
    border-radius: 20px 0 0 20px;
    background-color: #f4efec;;
    width: 50%;
}

.contact-main-accordion {
    border-radius: 0 20px 20px 0;
    background-color: #ce9c6a;
    background: linear-gradient(#ce9c6a, #e8ab75,#ce9c6a);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
}

@media (max-width: 1025px) {
    .contact-main {
    }

    .contact-main-container {
        gap: 50px !important;
        width: 100% !important;
        flex-direction: column !important;
        box-shadow: 0 0 0 !important;
    }

    .contact-main-form {
        border-radius: 20px !important;
        width: 100% !important;
    }

    .contact-main-accordion {
        border-radius: 20px !important;
        width: 100% !important;
    }
}

@media (max-width: 768px) {

    .contact-main-form {
        width: 100% !important;
    }

    .contact-main-accordion {
        width: 100% !important;
    }

    .contact-main {
        padding: 100px 20px !important;
    }
}



.nav-bar-main{
    display: flex;
    width: 100%;
    top: 0;
    padding: 20px;
    height: auto;
    z-index: 999;
    transition: 0.3s;
}
.nav-bar-main.sticky {
    position: fixed;
    display: flex;
    top: 0;
    padding: 20px;
    z-index: 999;
    transition: 0.3s;
    background-color: rgb(234, 233, 232);
}
.nav-bar-main.sticky.active {
    background-color: transparent;
}
.nav-bar-main.active{
    padding: 0;
}
.nav-bar-main.active .nav-bar-main-container {
    display: none;
}
.header-nav-bar-menu {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    top: 0;
    gap: 20px;
    height: 100vh;
    width: 100%;
    min-width: 250px;
    max-width: 300px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 999;
}
.header-nav-bar-menu svg {
    right: 0;
    cursor: pointer;
    width: 35px;
    height: 35px;
    color: white;
    z-index: 999;
}
.nav-bar-main-container{
    font-size: 20px;
    letter-spacing: 1px;
    width: 100%;
    color: white;
    font-weight: 700;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
}
.nav-bar-main-container-left{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.nav-bar-list{
    display: flex;
}
.nav-bar-list li {
    display: flex;
    padding: 0 20px;
}
.nav-bar-list li a {
    list-style: none;
    text-decoration: none;
    color: #fbf8f8;
    cursor: pointer;
    transition: 0.2s;
}
.nav-bar-main.sticky .nav-bar-list li a {
    font-style: italic;
    color: #2c2c2c;
}
.nav-bar-list li a:hover {
    scale: 1.05;
    font-weight: bold;
}
.nav-bar-main-container-middle{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
.nav-bar-logo{
    width: 100%;
}
.nav-bar-logo img{
    display: flex;
    width: 500px;
    height: 93px;
}
.nav-bar-main-container-right{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}
.nav-bar-cart{
    display: flex;
    align-items: center;
}
.nav-bar-menu{
    cursor: pointer;
    display: none;
}

.nav-bar-main.sticky .nav-bar-menu svg {
   color: #353434;
}
.nav-bar-menu:hover.nav-bar-menu svg{
    scale: 1.1 ;
    font-weight: bold;
}

@keyframes effectLogoWidht1250 {
    0% {
      transform: translateX(600px);
    }
    50% {

    }
    100% {
        transform: translateX(0);
    }
}
@keyframes effectLogoWidht768 {
    0% {
        transform: translateX(200px);
    }
    50% {

    }
    100% {
        transform: translateX(0);
    }
}

@media (max-width: 1250px) {
    .nav-bar-main{
        width: 100% !important;
        justify-content: space-between !important;
    }
    .nav-bar-main-container{
        width: 100% !important;
        justify-content: space-between !important;
    }
    .nav-bar-main-container-left{
        display: none;
    }
    .nav-bar-main-container-right{
        justify-content: end !important;
        gap: 50px !important;
        width: 100px  !important;
        height: 100% !important;
    }
    .nav-bar-logo img{
        width: 400px !important;
        height: 74px !important;
        animation: effectLogoWidht1250 1s;
    }
    .nav-bar-list{
        display: none;
    }
    .nav-bar-cart {

    }
    .nav-bar-menu {
        width: 35px;
        display: flex !important;
    }
    .nav-bar-menu svg{
        width: 35px !important;
        height: 35px !important;
    }
    .nav-bar-logo {
        width: 100% !important;
    }
}

@media (max-width: 768px) {
    .nav-bar-logo {
        width: 100% !important;
    }
    .nav-bar-main {
        padding: 10px;
    }
    .nav-bar-main-container {
        gap: 0 !important;
    }
    .nav-bar-logo img{
        width: 240px !important;
        height: 45px !important;
        animation: effectLogoWidht1250 1s;
    }
}
.header-menu-bar-main{
    display: flex;
    color: white;
    width: 100%;
    height: 100%;
    font-family: "Gilda Display", serif;
}
.header-menu-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.header-menu-bar-container-logo{
    padding: 20px;
}
.header-menu-bar-container-logo img{
    width: 240px;
    height: 45px;
}
.header-menu-bar-container-list {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.header-menu-bar-container-list a{
    text-align: center;
    cursor: pointer;
    padding: 20px;
    width: 100%;
    transition: 0.3s;
    border-bottom: 1px solid gray;
}
.header-menu-bar-container-list a:hover {
    font-weight: bold;
    padding: 25px;
}
.header-content-main {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}
.header-content-main-message {
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1240px;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 50px;
}
.header-content-message-star {
    display: flex;
    justify-content: center;
    gap: 10px;
}
.message-star-icon {
    width: 25px;
    height: 25px;
    color: #ffe104;
}
.header-content-message-introduction{
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 50px;
    text-shadow: 1px 1px 5px black;
    animation: effectStar 1s ease-in-out;
}

.header-content-message-text{
    font-size: 24px;
    width: 100%;
    text-shadow: 1px 1px 5px black;
    text-align: center;
    animation: effectMessage 1s ease-in-out;
}
@keyframes effectMessage {
    0% {
        transform: translateY(130px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes effectStar {
    0% {

        transform: translateY(-130px);
    }
    100% {
        transform: translateY(0px);
    }
}

@media (max-width: 1250px) {
    .header-content-main {
        justify-content: space-evenly !important;
        width: 100% !important;
        gap: 20px;
        padding: 0px !important;
        margin-top: 0 !important;
    }
    .header-content-main-message {
        width: 95%;
        font-size: 16px;
    }
    .header-content-message-introduction{
        font-weight: bolder;
        width: 70%;
    }
    .header-content-message-text{
        width: 80% !important;
        font-size: 20px;
        padding: 20px;
        text-shadow: 1px 1px 10px black;
    }
}
@media (max-width: 768px) {
    .header-content-message-introduction{
        font-weight: bolder;
        width: 100%;
        font-size: 32px;
        padding: 10px;
    }
    .header-content-message-text{
        width: 100% !important;
        font-size: 18px;
        padding: 10px;
        text-shadow: 1px 1px 10px black;
    }
}

@media (max-width: 360px){
    .header-content-message-introduction{
        font-size: 18px;
    }
    .header-content-message-text{
        font-size: 12px;
    }
}
.header-main {
    position: relative;
    width: 100%;
    display: flex;
    box-shadow: inset 1px 1px 20px rgba(0, 0, 0, 0.17);
    flex-direction: column;
    height: 100vh;
}

.header-background {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
    animation: backgroundAnimation 10s infinite;
}



.header-main-menubar {
    display: flex;
    flex-direction: column;
    box-shadow: 1px 1px 5px black;
    padding: 20px;
    align-items: flex-end;
    background-color: rgba(0, 0, 0, 0.82);
    position: absolute;
    width: 300px;
    height: 100%;
    z-index: 999;
}

.header-main-menubar svg {
    cursor: pointer;
    display: flex;
    justify-content: left;
    color: white;
    width: 30px;
    height: 30px;
    transition: 0.1s;
}

.header-main-menubar svg:hover {
    transform: scale(1.2);
}

@keyframes backgroundAnimation {
    0% { opacity: 0.5; }
    20% { opacity: 1; }
    50% { opacity: 1; }
    100% { opacity: 1; }
}

@media (max-width: 1024px) {
    .header-main::before {
        position: fixed;
    }

}

.model-services-main{
    display: flex;
    justify-content: center;
    padding: 150px 100px;
    width: 100%;
    background-color: #202020;
}
.model-services-container {
    border-radius: 25px;
    box-shadow: 1px 1px 50px #1b1a1a;
    display: flex;
    background-color: #3c3c3c;
    width: 100%;
    max-width: 1240px;
    height: auto;
    gap: 50px;
}
.model-services-container-left {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px;
}
.model-services-container-left h1 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.model-services-container-left h1 svg {
    margin-top: 5px;
}
.model-services-title {
    display: flex;
    width: 100%;
    height: auto;
    color:  #ca8955;
    font-size: 36px;
}
.model-services-contact, .model-services-strategy , .model-services-execution, .model-services-help  {
    display: flex;
    gap: 20px;
}
.model-services-number {
    display: flex;
    position: relative;
    background-color: #e8ab75;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-weight: bold;
    font-size: 20px;
    width: 100px;
    min-width: 100px;
    height: 100%;
    max-height: 150px;
    min-height: 150px;
}

.model-services-number::before{
    content: "";
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
}
.model-services-number::after {
    content: "";
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    right: 0;
    border-style: solid;
}

.model-services-number::before {
    top: 0;
    position: absolute;
    border-width: 0 50px 25px 50px;
    border-color: transparent transparent #3c3c3c transparent;
    transform: rotate(180deg);
}
.model-services-number::after{
    bottom: 0;
    position: absolute;
    transform: rotate(180deg);
    border-width: 0 50px 25px 50px;
    border-color: #3c3c3c #3c3c3c transparent #3c3c3c ;
    ;

}
.model-services-content {
    color: #ccc8c8;
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 18px;
    height: auto;
}
.model-services-container-right {
    display: flex;
    height: 100%;
    width: 70%;
    object-fit: cover;
    gap: 30px;
}
.model-services-container-right img {
    display: flex;
    width: 100%;
    border-radius: 25px;
    object-fit: cover;
}

@media (max-width: 1024px) {
    .model-services-container-right {
        display: none;
    }
}

@media (max-width: 768px) {

    .model-services-main{
        padding: 100px 20px !important;
    }
    .model-services-container-left {
        padding: 10px !important;
    }
    .model-services-title {
        width: 100%;
        font-size: 32px;
        text-align: left;
    }
    .model-services-content {
        gap: 5px !important;
    }
    .model-services-content header h2 {
        font-size: 20px;
    }
    .model-services-content p {
        font-size: 16px;
    }
    .model-services-number {
        width: 70px !important;
        min-width: 70px !important;
        height: 100% !important;
        max-height: 200px !important;
    }

    .model-services-number::before {
        top: 0;
        position: absolute;
        border-width: 0 35px 15px 35px;
        border-color: transparent transparent #3c3c3c transparent;
        transform: rotate(180deg);
    }
    .model-services-number::after{
        bottom: 0;
        position: absolute;
        transform: rotate(180deg);
        border-width: 0 35px 15px 35px;
        border-color: #3c3c3c #3c3c3c transparent #3c3c3c ;
    ;
    }
}

