.contact-main {
    display: flex;
    justify-content: center;
    padding: 150px 100px;
    height: auto;
    background: linear-gradient(#363636, rgb(32, 32, 32));
    box-shadow: inset 0px 10px 10px #2c2b2b;
}

.contact-main-container {
    width: 100%;
    max-width: 1250px;
    box-shadow: 1px 1px 10px black;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
}

.contact-main-form {
    border-radius: 20px 0 0 20px;
    background-color: #f4efec;;
    width: 50%;
}

.contact-main-accordion {
    border-radius: 0 20px 20px 0;
    background-color: #ce9c6a;
    background: linear-gradient(#ce9c6a, #e8ab75,#ce9c6a);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
}

@media (max-width: 1025px) {
    .contact-main {
    }

    .contact-main-container {
        gap: 50px !important;
        width: 100% !important;
        flex-direction: column !important;
        box-shadow: 0 0 0 !important;
    }

    .contact-main-form {
        border-radius: 20px !important;
        width: 100% !important;
    }

    .contact-main-accordion {
        border-radius: 20px !important;
        width: 100% !important;
    }
}

@media (max-width: 768px) {

    .contact-main-form {
        width: 100% !important;
    }

    .contact-main-accordion {
        width: 100% !important;
    }

    .contact-main {
        padding: 100px 20px !important;
    }
}


