.nav-bar-main{
    display: flex;
    width: 100%;
    top: 0;
    padding: 20px;
    height: auto;
    z-index: 999;
    transition: 0.3s;
}
.nav-bar-main.sticky {
    position: fixed;
    display: flex;
    top: 0;
    padding: 20px;
    z-index: 999;
    transition: 0.3s;
    background-color: rgb(234, 233, 232);
}
.nav-bar-main.sticky.active {
    background-color: transparent;
}
.nav-bar-main.active{
    padding: 0;
}
.nav-bar-main.active .nav-bar-main-container {
    display: none;
}
.header-nav-bar-menu {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    top: 0;
    gap: 20px;
    height: 100vh;
    width: 100%;
    min-width: 250px;
    max-width: 300px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 999;
}
.header-nav-bar-menu svg {
    right: 0;
    cursor: pointer;
    width: 35px;
    height: 35px;
    color: white;
    z-index: 999;
}
.nav-bar-main-container{
    font-size: 20px;
    letter-spacing: 1px;
    width: 100%;
    color: white;
    font-weight: 700;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
}
.nav-bar-main-container-left{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.nav-bar-list{
    display: flex;
}
.nav-bar-list li {
    display: flex;
    padding: 0 20px;
}
.nav-bar-list li a {
    list-style: none;
    text-decoration: none;
    color: #fbf8f8;
    cursor: pointer;
    transition: 0.2s;
}
.nav-bar-main.sticky .nav-bar-list li a {
    font-style: italic;
    color: #2c2c2c;
}
.nav-bar-list li a:hover {
    scale: 1.05;
    font-weight: bold;
}
.nav-bar-main-container-middle{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
.nav-bar-logo{
    width: 100%;
}
.nav-bar-logo img{
    display: flex;
    width: 500px;
    height: 93px;
}
.nav-bar-main-container-right{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}
.nav-bar-cart{
    display: flex;
    align-items: center;
}
.nav-bar-menu{
    cursor: pointer;
    display: none;
}

.nav-bar-main.sticky .nav-bar-menu svg {
   color: #353434;
}
.nav-bar-menu:hover.nav-bar-menu svg{
    scale: 1.1 ;
    font-weight: bold;
}

@keyframes effectLogoWidht1250 {
    0% {
      transform: translateX(600px);
    }
    50% {

    }
    100% {
        transform: translateX(0);
    }
}
@keyframes effectLogoWidht768 {
    0% {
        transform: translateX(200px);
    }
    50% {

    }
    100% {
        transform: translateX(0);
    }
}

@media (max-width: 1250px) {
    .nav-bar-main{
        width: 100% !important;
        justify-content: space-between !important;
    }
    .nav-bar-main-container{
        width: 100% !important;
        justify-content: space-between !important;
    }
    .nav-bar-main-container-left{
        display: none;
    }
    .nav-bar-main-container-right{
        justify-content: end !important;
        gap: 50px !important;
        width: 100px  !important;
        height: 100% !important;
    }
    .nav-bar-logo img{
        width: 400px !important;
        height: 74px !important;
        animation: effectLogoWidht1250 1s;
    }
    .nav-bar-list{
        display: none;
    }
    .nav-bar-cart {

    }
    .nav-bar-menu {
        width: 35px;
        display: flex !important;
    }
    .nav-bar-menu svg{
        width: 35px !important;
        height: 35px !important;
    }
    .nav-bar-logo {
        width: 100% !important;
    }
}

@media (max-width: 768px) {
    .nav-bar-logo {
        width: 100% !important;
    }
    .nav-bar-main {
        padding: 10px;
    }
    .nav-bar-main-container {
        gap: 0 !important;
    }
    .nav-bar-logo img{
        width: 240px !important;
        height: 45px !important;
        animation: effectLogoWidht1250 1s;
    }
}