.header-content-main {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}
.header-content-main-message {
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1240px;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 50px;
}
.header-content-message-star {
    display: flex;
    justify-content: center;
    gap: 10px;
}
.message-star-icon {
    width: 25px;
    height: 25px;
    color: #ffe104;
}
.header-content-message-introduction{
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 50px;
    text-shadow: 1px 1px 5px black;
    animation: effectStar 1s ease-in-out;
}

.header-content-message-text{
    font-size: 24px;
    width: 100%;
    text-shadow: 1px 1px 5px black;
    text-align: center;
    animation: effectMessage 1s ease-in-out;
}
@keyframes effectMessage {
    0% {
        transform: translateY(130px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes effectStar {
    0% {

        transform: translateY(-130px);
    }
    100% {
        transform: translateY(0px);
    }
}

@media (max-width: 1250px) {
    .header-content-main {
        justify-content: space-evenly !important;
        width: 100% !important;
        gap: 20px;
        padding: 0px !important;
        margin-top: 0 !important;
    }
    .header-content-main-message {
        width: 95%;
        font-size: 16px;
    }
    .header-content-message-introduction{
        font-weight: bolder;
        width: 70%;
    }
    .header-content-message-text{
        width: 80% !important;
        font-size: 20px;
        padding: 20px;
        text-shadow: 1px 1px 10px black;
    }
}
@media (max-width: 768px) {
    .header-content-message-introduction{
        font-weight: bolder;
        width: 100%;
        font-size: 32px;
        padding: 10px;
    }
    .header-content-message-text{
        width: 100% !important;
        font-size: 18px;
        padding: 10px;
        text-shadow: 1px 1px 10px black;
    }
}

@media (max-width: 360px){
    .header-content-message-introduction{
        font-size: 18px;
    }
    .header-content-message-text{
        font-size: 12px;
    }
}