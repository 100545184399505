.header-main {
    position: relative;
    width: 100%;
    display: flex;
    box-shadow: inset 1px 1px 20px rgba(0, 0, 0, 0.17);
    flex-direction: column;
    height: 100vh;
}

.header-background {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
    animation: backgroundAnimation 10s infinite;
}



.header-main-menubar {
    display: flex;
    flex-direction: column;
    box-shadow: 1px 1px 5px black;
    padding: 20px;
    align-items: flex-end;
    background-color: rgba(0, 0, 0, 0.82);
    position: absolute;
    width: 300px;
    height: 100%;
    z-index: 999;
}

.header-main-menubar svg {
    cursor: pointer;
    display: flex;
    justify-content: left;
    color: white;
    width: 30px;
    height: 30px;
    transition: 0.1s;
}

.header-main-menubar svg:hover {
    transform: scale(1.2);
}

@keyframes backgroundAnimation {
    0% { opacity: 0.5; }
    20% { opacity: 1; }
    50% { opacity: 1; }
    100% { opacity: 1; }
}

@media (max-width: 1024px) {
    .header-main::before {
        position: fixed;
    }

}
